<template>
  <b-container>
    <b-navbar
      class="mt-3"
      style="z-index: 2"
      toggleable="md"
    >
      <b-form-row>
        <b-col
          md="auto"
          sm="12"
        >
          <b-navbar-brand
            :to="{ name: 'HomeDefault' }"
            style="width: 100%"
          >
            <img
              src="@/assets/JustText_Colour.svg"
              style="width: 100%; height: 100%"
            >
            <h2
              class="tagline m-auto"
            >
              Outlet site for accommodations
            </h2>
          </b-navbar-brand>
        </b-col>
        <b-col class="mt-2">
          <b-row>
            <b-col>
              <b-navbar-toggle
                target="navbar-toggle-collapse"
                block
              />
            </b-col>
            <b-col cols="auto">
              <b-button
                size="md"
                variant="primary"
                block
                :to="{
                  name: $store.getters.user.loggedIn ? 'Advertenties' : 'Login',
                }"
              >
                <font-awesome-icon icon="newspaper" />
                {{ $t("buttons.publish_ad") }} / {{ $t("buttons.login") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-form-row>
    </b-navbar>

    <b-navbar
      style="z-index: 2"
      class="mb-2 p-0"
      toggleable="md"
    >
      <b-collapse
        id="navbar-toggle-collapse"
        is-nav
      >
        <b-navbar-nav
          class="ml-auto"
          v-if="$store.getters.user.loggedIn"
        >
          <b-nav-item
            v-for="i in items"
            :to="{ name: i.name }"
            :key="i.name"
          >
            {{ $t(i.label) }}
          </b-nav-item>

          <b-nav-item
            right
            :to="{ name: 'Accomodatie' }"
          >
            <font-awesome-icon
              :icon="
                $store.getters.user.data.emailVerified ? 'user-check' : 'user'
              "
            />
            {{ $t("navigation.profile") }}
          </b-nav-item>
          <b-nav-item @click="logout">
            {{ $t("navigation.logout") }}
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav
          class="ml-auto"
          v-else
        >
          <b-nav-item
            v-for="i in items"
            :to="{ name: i.name }"
            :key="i.name"
          >
            {{ $t(i.label) }}
          </b-nav-item>
        </b-navbar-nav>

        <b-button
          variant="icon"
          href="https://www.twitter.com/booknitescom"
          target="_blank"
        >
          <font-awesome-icon
            style="color:white"
            class="fa-lg"
            :icon="['fab', 'twitter']"
          />
        </b-button>
        <b-button
          variant="icon"
          @click="changeLanguage('en')"
        >
          <country-flag
            country="gb"
            class="align-middle"
          />
        </b-button>

        <b-button
          variant="icon"
          @click="changeLanguage('nl')"
        >
          <country-flag
            country="nl"
            class="align-middle"
          />
        </b-button>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>
<script>
import { db, auth } from '@/firebase'
import CountryFlag from 'vue-country-flag'
import store from '@/store'
export default {
  components: {
    CountryFlag
  },
  data () {
    return {
      showSidebar: true,
      items: [
        { name: 'HomeDefault', label: 'navigation.home' },
        { name: 'About', label: 'navigation.about' },
        { name: 'Contact', label: 'navigation.contact' }
        // { name: 'Blog', label: 'navigation.blog' }
      ]
    }
  },
  methods: {
    changeLanguage (lang) {
      console.log(lang)
      this.$root.$i18n.locale = lang.toLowerCase()
      // console.log(this.$store.getters.)
      this.$moment.locale = lang.toLowerCase()
      console.log(this.$moment.locale)
      store.dispatch('changeLanguage', lang.toUpperCase())
      this.loadLanguage(lang.toLowerCase())
    },
    loadLanguage (lang) {
      db.doc(`i18n/${lang}`)
        .get()
        .then((response) => {
          this.$root.$i18n.setLocaleMessage(lang, response.data())
          console.log(response.data())
        })
    },
    logout () {
      auth.signOut().then(() => {
        this.$router.replace({
          name: 'HomeDefault'
        })
      })
    }
  }
}
</script>
