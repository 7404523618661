<template>
  <div>
    <NavHeader />
    <keep-alive include="AdGrid">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import NavHeader from '../components/NavHeader'
export default {
  components: {
    NavHeader
  }
}
</script>
